@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}