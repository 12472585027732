hr.striped {
    margin: 1.4rem 0;
    height: 1px;
    background: none;
    border: none;
    background-image: linear-gradient(90deg,#97b3b5 35%,hsla(0,0%,100%,0) 0);
    background-position: 50%;
    background-size: 38px 3px;
    background-repeat: repeat-x;
    opacity: unset;
}