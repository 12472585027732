.page {
    min-height: 100vh;
    padding: 3rem;
    padding-bottom: 6rem !important;
    
    &--ignore {
        padding: 0;
    }
    
    &__title {
        font-size: clamp(2.5rem, 4rem, 4rem);
        margin: 0;
        line-height: 100%;
    }
    
    &__subtitle {
        font-size: clamp(1.1rem, 1.5rem, 1.8rem);
        font-weight: 500;
        margin: 0;
    }
}