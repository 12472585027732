.page--access-denied {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        img {
            border-radius: 100%;
        }
        
        h2, p {
            margin-bottom: 0;
        }
        
        p {
            margin-top: 0;
        }
    }
}