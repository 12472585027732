.btn, button {
    display: flex;
    align-items: center;
    padding: 0;
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    
    &:not(.btn--blank) {
        width: fit-content;
        height: fit-content;
        padding: .4rem 3rem;
        background-color: $blue500;
        border-radius: 120px;
        white-space: nowrap;
        font-weight: 700;
        color: $blue100;
    }
    
    &:not(.btn--icon) {
        justify-content: center;
    }
    
    &--icon {
        position: relative;
        // display: flex;
        align-items: center;
        padding-left: 2.8rem !important;
        padding-right: 1rem !important;
        
        box-icon {
            position: absolute;
            left: calc(.6rem - 0px);
            fill: $blue100 !important;
            width: 19px;
            height: 19px;
        }
    }
    
    &--sub {
        background-color: #aababc !important;
    }
    
    &--simple {
        background-color: transparent !important;
        font-weight: 500;
        color: $blue400 !important;
        
        box-icon {fill: $blue300 !important;}
    }
    
    &--w-full {
        width: 100% !important;
    }
}

.btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    
    
    &.btn-group--stretch {
        width: 100%;
        .btn, .button, button {
            width: 100%;
        }
    } 
}

@media (min-width: 576px) {}

@media (min-width: 768px) {
    .btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        
        
        &.btn-group--stretch {
            width: fit-content;
            
            .btn, .button, button {
                width: fit-content;
            }
        } 
    }
}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}