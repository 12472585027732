@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.popup-content {
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.popup-content {
    &.edit-cost-content {
        position: fixed !important;
        left: 0px; right: 0px; 
        bottom: 0px;
        padding: 1.4rem;
        border: none;
        border-radius: $bdRadius $bdRadius 0 0; 
    }
}


.popup-content.menu-content {
  padding: 1.4rem;
  background-color: $blue100;
  border: none;
  border-radius: $bdRadius !important;
}

.tooltip--support-content {
  width: 400px !important;
}