@import '../node_modules/sanitize.css/sanitize.css';
@import '../node_modules/bootstrap/scss/bootstrap-utilities.scss';
@import '../node_modules/bootstrap/scss/bootstrap-grid.scss';
@import '../node_modules/react-tabs/style/react-tabs.css';
@import '../node_modules/@kenshooui/react-multi-select/dist/style.css';
@import '../node_modules/react-toastify/dist/ReactToastify.css';

@import './scss/abstracts';
@import './scss/base';
@import './scss/components';
@import './scss/vendors';