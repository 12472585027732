.notify-nonetwork {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    
    img {
        margin-bottom: 1rem;
    }
    
    p {
        margin: 0;
    }
    
    button {
        margin-top: 1rem;
    }
}