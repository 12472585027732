.card--share {  
    cursor: pointer;
    
    &, .card__header, .card__body {
        display: flex;
        align-items: center;
        justify-content: space-between; 
        gap: 1rem; 
    }
    
    h3 {
        margin: 0;
        
        span {
            font-weight: 500;
            font-size: 1rem;
        }
    }
         
    .card__body {
        box-icon {
            width: 20px;
            height: 20px;
        }
    }
    
    & + & {
        margin-top: 1rem;
    }
}