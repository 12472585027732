@import '../../scss/abstracts';

.page--budget {
    header {
        background-color: $grey200;
    }
    
    .page__title {
        font-size: clamp(1.7rem, 2rem, 2rem);
    }
    
    .page__subtitle {
        font-size: clamp(1.1rem, 1.25rem, 1.25rem);
    }
}