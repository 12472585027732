@import '../../scss/abstracts';

.card {
    padding: 1rem;
    background-color: $blue100;
    color: $blue500;
    border-radius: $bdRadius;
    
    box-icon {
        fill: $blue500;
    }
    
    small, p {
        font-weight: 500;
    }
}