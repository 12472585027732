@import "../../scss/abstracts";

.page--start {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    text-align: center;
    
    h1 {
        margin: 0;
    }
    
    .section--groups-list {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: 1.4rem;
        justify-content: center;
        
        .group--prior {
            order: 1;
        }
        
        .group--no-prior .card {
            opacity: .9;
            background-color: transparent;
            border: 2px solid $blue100;
        }
    }
}