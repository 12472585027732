$grey500: #707070;
$grey400: #969696;
$grey300: #c7c7c7;
$grey200: #F7F7F7;

$blue500: #4D777B;
$blue400: #738e90;
$blue300: #97B3B5;
$blue200: #E1E9EA;
$blue100: #ECF7F8;
$blue000: #f8fbfb;

$bdRadius: 12px;

$shadowLg: 0px 0px 11px -6px #00000040, 0px 12px 43px 6px #0000001a;