.Toastify__progress-bar {
    background-color: grey;
}

.Toastify__progress-bar--default {
    background: $blue300;
}

.Toastify__toast {
    padding: 1rem;
    font-family: 'Gilroy';
    border-radius: $bdRadius/2;
    color: $blue500;
    font-weight: 500;
    background-color: $blue000;
}

.Toastify__close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px !important; height: 24px !important;
    padding: .2rem !important;
    background-color: transparent !important;
    
    svg {
        transform: translate(1px, 0px);
        fill: $blue500;
    }
}