h1, h2, h3, h4, h5 {
    &:first-child {margin-top: 0;}
    &:last-child {margin-bottom: 0;}
}

p {
    &:first-child {margin-top: 0;}
    &:last-child {
        margin-bottom: 0;
    }
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}